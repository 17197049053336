import { useTranslation } from "react-i18next";
import { HiPlus } from "react-icons/hi";
import { Link } from "react-router-dom";
import Table from "../../list/Table";
import { useApp } from "../../providers/app";
import { formatDate, more, translateCell } from "../../providers/format";
import { CreatedBy } from "../../template";
import { BsPlusSquare } from "react-icons/bs";
import objectPath from "object-path";
import { DATE_FORMAT_VIEW, STATES, SYSTEMS } from "../../defines";
import moment from "moment";
export const ROLE_LIST = [
  {
    label: "Төлөгдсөн",
    value: true,
  },
  {
    label: "Төлөгдөөгүй",
    value: false,
  },
];
export const LIST = [
  {
    label: "Улаанбаатар",
    value: true,
  },
  {
    label: "Орон нутаг",
    value: false,
  },
];

const InvoiceList = () => {
  const { user } = useApp();

  return (
    <div>
      <div className="w-full flex flex-col gap-5 bg-white p-8">
        <div className="flex flex-row justify-between items-center w-full">
          <h2 className="bordered semibold text-green-100">{"Нэхэмжлэх"}</h2>
          <Link className="hover:cursor-pointer" to="new">
            <BsPlusSquare size={22} color={"rgba(1, 167, 83, 1)"} />
          </Link>
        </div>

        <Table
          url={"/invoice/table"}
          defaultFind={{}}
          sort={{ created: -1 }}
          columns={[
            {
              label: "Нэхэмжлэх №",
              key: "number",
              sortable: true,
              filter: {
                type: "text",
              },
              render: ({ _id, number }) => (
                <Link to={`view/${_id}`}>
                  <div className=" underline text-green-100">{number}</div>
                </Link>
              ),
            },
            {
              label: "Нэхэмжлэхийн огноо",
              key: "created",
              filter: {
                type: "text",
              },
              render: ({ created }) => <div>{formatDate(created)}</div>,
            },
            {
              label: "Төлсөн эсэх",
              key: "is_paid",
              filter: {
                type: "select",
                items: ROLE_LIST,
                axioAdapter: (data) => {
                  return data?.map(({ name }) => ({
                    value: name,
                    label: name,
                  }));
                },
              },
              render: ({ isPaid }) => (
                <div>{isPaid == true ? "Төлөгдсөн" : "Төлөгдөөгүй"}</div>
              ),
            },
            {
              label: "Харьяа",
              key: "is_city",
              filter: {
                type: "select",
                items: LIST,
                axioAdapter: (data) => {
                  return data?.map(({ name }) => ({
                    value: name,
                    label: name,
                  }));
                },
              },
              render: ({ is_city }) => (
                <div>{is_city == true ? "Улаанбаатар" : "Орон нутаг"}</div>
              ),
            },
            {
              label: "Статус",
              key: "status",
              sortable: true,

              filter: {
                type: "select",
                items: [
                  { label: "Хүлээгдэж буй", value: "pending" },
                  { label: "Төлсөн", value: "paid" },
                  { label: "Хөнглөгдсөн", value: "discount" },
                ],
              },
              render: ({ status }) => (
                <div>{objectPath.get(STATES, status)}</div>
              ),
            },
            {
              label: "Мөнгөн дүн",
              key: "amount",
              filter: {
                type: "text",
              },
              render: ({ amount }) => (
                <div>
                  {amount
                    ?.toString()
                    ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                </div>
              ),
            },
            {
              label: "Бүртгэгдсэн огноо",
              key: "created",
              filter: {
                type: "text",
              },
              render: ({ created }) => (
                <div>{moment(created).format(DATE_FORMAT_VIEW)}</div>
              ),
            },
            {
              label: "Улсын дугаар",
              key: "plate",
              filter: {
                type: "text",
              },
            },
            {
              label: "Арлын дугаар",
              key: "cabin",
              sortable: true,
              filter: {
                type: "text",
              },
            },

            {
              label: "Модель",
              key: "mark",
              sortable: true,
              filter: {
                type: "text",
              },
            },

            {
              label: "Модель",
              key: "model",
              sortable: true,
              filter: {
                type: "text",
              },
            },
            {
              label: "Систем",
              key: "system",
              filter: {
                type: "select",
                items: [{ label: "Төлбөр авах цэг", value: "tollgate" }],
                axioAdapter: (data) => {
                  return data?.map(({ name }) => ({
                    value: name,
                    label: name,
                  }));
                },
              },
              render: ({ system }) => (
                <div>{objectPath.get(SYSTEMS, system)}</div>
              ),
            },
            {
              label: "Зам",
              key: "road",
              filter: {
                type: "text",
              },
            },

            {
              label: "Тэмдэглэл",
              key: "description",
              filter: {
                type: "text",
              },
            },
            {
              label: "Орсон ТАЦ",
              key: "in_tollgate",
              filter: {
                type: "text",
              },
            },
            {
              label: "Орсон огноо",
              key: "in_date",
              filter: {
                type: "date",
              },
            },
          ]}
        />
      </div>
    </div>
  );
};

export default InvoiceList;
